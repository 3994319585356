<script
    lang="ts"
    setup
>
    type Props = {
        disabled: boolean
        loading?: boolean
        errorMessage?: string
        small?: boolean
        alwaysActive?: boolean
        textarea?: boolean
        textareaLarge?: boolean
        textareaMedium?: boolean
        textareaSmall?: boolean
        withoutErrorText?: boolean
    }

    const props = withDefaults(defineProps<Props>(), {
        disabled: false,
        loading: false,
        errorMessage: undefined,
        small: false,
        alwaysActive: false,
        textarea: false,
        textareaLarge: undefined,
        textareaMedium: undefined,
        textareaSmall: undefined,
        withoutErrorText: undefined,
    })

    const focused = ref<boolean>(false)

    const style = useCssModule()

    const wrapperClass = computed<string[]>(() => {
        const classes = [ style['field-wrapper'] ]

        if (props.disabled) {
            classes.push(style['field-wrapper--disabled'])
        }

        if (props.errorMessage) {
            classes.push(style['field-wrapper--has-error'])
        }

        if (props.textarea) {
            classes.push(style['field-wrapper--textarea'])

            if (props.textareaSmall) {
                classes.push(style['field-wrapper--textarea--small'])
            } else if (props.textareaLarge) {
                classes.push(style['field-wrapper--textarea--large'])
            } else {
                classes.push(style['field-wrapper--textarea--medium'])
            }
        } else if (props.small) {
            classes.push(style['field-wrapper--small'])
        }

        return classes
    })

    const wrapperMarginBottomValue = computed<string | undefined>(() => {
        if (props.small || props.withoutErrorText) {
            return '0'
        }

        return '16px'
    })

    defineExpose({ focused })
</script>

<template>
    <label
        :class="wrapperClass"
        @focusin="focused = true"
        @focusout="focused = false"
    >
        <div
            key="field-container"
            :class="$style['field-wrapper__container']"
        >
            <span
                v-if="$slots['prefix']"
                key="prefix"
                :class="$style['field-wrapper__container__prefix']"
            >
                <slot name="prefix" />
            </span>

            <div
                key="field"
                :class="$style['field-wrapper__container__content']"
            >
                <slot
                    :focused="focused"
                    :input-class="[
                        $style['field-wrapper__container__content__input'],
                        { [$style['input-always-active']]: props.alwaysActive },
                    ]"
                    :label-class="$style['field-wrapper__container__content__label']"
                />
            </div>

            <span
                v-if="$slots['suffix']"
                key="suffix"
                :class="$style['field-wrapper__container__suffix']"
            >
                <slot name="suffix" />
            </span>

            <div
                v-if="props.loading"
                key="loader"
                :class="$style['field-wrapper__container__loader']"
            ></div>
        </div>

        <AppFormFieldError
            v-if="!props.withoutErrorText"
            key="error"
            :disabled="props.disabled"
            :error-message="props.errorMessage"
        />

        <slot name="bottom" />
    </label>
</template>

<style
    lang="sass"
    module
>
    .field-wrapper
        display: flex
        flex-direction: column
        align-items: center
        width: var(--wrapper-width, 300px)
        margin-bottom: v-bind('wrapperMarginBottomValue')
        padding: 0
        cursor: text

        $wrapper: &

        --container-height: 54px
        --content-height: 34px
        --input-height-active: 18px
        --error-color: #dd0e0e

        &--disabled
            cursor: not-allowed

            > *
                pointer-events: none

            #{$wrapper}__container
                border-color: #e0e2ea

        &--has-error &
            &__container
                border-color: var(--error-color) !important

        &--small
            --wrapper-width: 256px
            --container-height: 38px
            --container-pading: 6px
            --content-height: 24px
            --input-height-active: 18px

        &--textarea--large
            --container-height: 144px
            --content-height: 124px
            --input-height-active: 108px

        &--textarea--medium
            --container-height: 90px
            --content-height: 70px
            --input-height-active: 54px

        &--textarea--small
            --container-height: 70px
            --content-height: 50px
            --input-height-active: 34px

        &__container
            position: relative
            display: flex
            align-items: center
            min-height: var(--container-height)
            width: 100%
            padding: var(--container-pading, 9px 16px)
            border: 1px solid #aaadb8
            border-radius: 4px
            background: #fff
            transition: border-color var(--transition-default-duration-with-ease)
            will-change: border-color

            $container: &

            &:focus-within
                border-color: #000

                #{$container}__content
                    pointer-events: inherit

            &__content
                pointer-events: none
                order: 1
                flex-grow: 1
                display: flex
                flex-direction: column
                justify-content: center
                align-items: flex-start
                min-height: var(--content-height)
                width: 225px
                padding: 0

                $content: &

                #{$wrapper}:hover &
                    pointer-events: inherit

                #{$wrapper}--textarea &
                    justify-content: flex-start

                &__input
                    order: 1
                    appearance: none
                    outline: none
                    border: none
                    opacity: 0
                    height: 0
                    width: 100%
                    padding: 0
                    font-style: normal
                    font-weight: 400
                    font-size: 14px
                    line-height: 130%
                    text-overflow: ellipsis
                    background: none
                    color: #000
                    caret-color: auto
                    transition: opacity var(--transition-default-duration-with-ease), height var(--transition-default-duration-with-ease)
                    will-change: opacity, height

                    --placeholder-color: #8a8f9e

                    &:focus,
                    &:not(:placeholder-shown),
                    &.input-always-active
                        opacity: 1
                        height: var(--input-height-active)

                        + #{$content}__label
                            font-size: 12px

                    &::-webkit-input-placeholder
                        color: var(--placeholder-color)

                    &::-moz-placeholder
                        color: var(--placeholder-color)

                    &:-ms-input-placeholder
                        color: var(--placeholder-color)

                    &:-moz-placeholder
                        color: var(--placeholder-color)

                &__label
                    order: 0
                    height: 16px
                    font-style: normal
                    font-weight: 400
                    font-size: 14px
                    line-height: 130%
                    color: #8a8f9e
                    user-select: none
                    -webkit-touch-callout: none
                    transition: font-size var(--transition-default-duration-with-ease)
                    will-change: font-size

            &__prefix,
            &__suffix
                display: flex

            &__prefix
                order: 0
                margin-right: 10px

            &__suffix
                order: 2
                margin-left: 10px

            &__loader
                position: absolute
                overflow: hidden
                bottom: 0
                left: 0
                width: 100%
                height: 2px

                &:before
                    content: ''
                    position: absolute
                    left: -50%
                    width: 30%
                    height: inherit
                    animation: line-animation 600ms ease-in-out infinite
                    background-color: var(--color-primary)
                    border-radius: 10px

                @keyframes line-animation
                    0%
                        left: -30%
                    50%
                        left: 10%
                        width: 80%
                    100%
                        left: 100%
                        width: 100%
</style>
