import payload_plugin_dHWDzdkIDW from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.31.0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_qm3hPsWudS from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4._ba15838319f1a20aa7bb6804b171f370/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_fnMmWy85KF from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4._ba15838319f1a20aa7bb6804b171f370/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_0U5373isFI from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4._ba15838319f1a20aa7bb6804b171f370/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_xNJkNeCBwQ from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@4.31.0_vite@6.0.11_@types+node@22.10.7_jit_15c2bfe35088618b44ed1f55a8cc374b/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_jv5Vfo0sJf from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4._ba15838319f1a20aa7bb6804b171f370/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_nvQ1NBr0pz from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4._ba15838319f1a20aa7bb6804b171f370/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_YljDwukRCr from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4._ba15838319f1a20aa7bb6804b171f370/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_ZENDn4j31W from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4._ba15838319f1a20aa7bb6804b171f370/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_UtRw2ejqZa from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.31.0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/Users/ilya/Sites/spilky-landing/.nuxt/components.plugin.mjs";
import prefetch_client_WAw96A02bK from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4._ba15838319f1a20aa7bb6804b171f370/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import siteConfig_sPfPJpNI9V from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.31.0_vite@6.0.11_@types+node@22.10.7_jiti@_d31a5885f834ef31d79e7994f9e17b01/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_XD14K4l1FB from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.31.0_vite@6.0.11_@types+node@22.10.7_jiti@_d31a5885f834ef31d79e7994f9e17b01/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_11Zfmk65WX from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.31.0_vite@6.0.11_@types+node@22.10.7_jiti@_d31a5885f834ef31d79e7994f9e17b01/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import _01_localization_Sjiymv6NFV from "/Users/ilya/Sites/spilky-landing/plugins/01.localization.ts";
import _02_widget_client_yEJB2c6tIw from "/Users/ilya/Sites/spilky-landing/plugins/02.widget.client.ts";
import _03_popupManager_or3NqQcoKq from "/Users/ilya/Sites/spilky-landing/plugins/03.popupManager.ts";
import defaults_z6MMUq4fqN from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.31.0_vite@6.0.11_@types+node@22.10.7_jiti@_d31a5885f834ef31d79e7994f9e17b01/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  payload_plugin_dHWDzdkIDW,
  revive_payload_client_qm3hPsWudS,
  unhead_fnMmWy85KF,
  router_0U5373isFI,
  _0_siteConfig_xNJkNeCBwQ,
  payload_client_jv5Vfo0sJf,
  navigation_repaint_client_nvQ1NBr0pz,
  check_outdated_build_client_YljDwukRCr,
  chunk_reload_client_ZENDn4j31W,
  plugin_vue3_UtRw2ejqZa,
  components_plugin_KR1HBZs4kY,
  prefetch_client_WAw96A02bK,
  siteConfig_sPfPJpNI9V,
  inferSeoMetaPlugin_XD14K4l1FB,
  titles_11Zfmk65WX,
  _01_localization_Sjiymv6NFV,
  _02_widget_client_yEJB2c6tIw,
  _03_popupManager_or3NqQcoKq,
  defaults_z6MMUq4fqN
]