
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as helpcrunchThavmf0J3yMeta } from "/Users/ilya/Sites/spilky-landing/pages/[[language]]/comparison/helpcrunch.vue?macro=true";
import { default as jivoSMTYsxPiusMeta } from "/Users/ilya/Sites/spilky-landing/pages/[[language]]/comparison/jivo.vue?macro=true";
import { default as indexUkQhYVIbSwMeta } from "/Users/ilya/Sites/spilky-landing/pages/[[language]]/index.vue?macro=true";
import { default as privacy_45policyI30wEseNi0Meta } from "/Users/ilya/Sites/spilky-landing/pages/[[language]]/privacy-policy.vue?macro=true";
import { default as tariffsUPlKWr9RcPMeta } from "/Users/ilya/Sites/spilky-landing/pages/[[language]]/tariffs.vue?macro=true";
import { default as user_45agreementFzqNtvkX2BMeta } from "/Users/ilya/Sites/spilky-landing/pages/[[language]]/user-agreement.vue?macro=true";
import { default as _403Aov5PKaN5rMeta } from "/Users/ilya/Sites/spilky-landing/pages/errors/403.vue?macro=true";
import { default as _404lHVSljmyHrMeta } from "/Users/ilya/Sites/spilky-landing/pages/errors/404.vue?macro=true";
import { default as _500VgkNbRSvIpMeta } from "/Users/ilya/Sites/spilky-landing/pages/errors/500.vue?macro=true";
export default [
  {
    name: "comparison-helpcrunch",
    path: "/:language?/comparison/helpcrunch",
    meta: helpcrunchThavmf0J3yMeta || {},
    component: () => import("/Users/ilya/Sites/spilky-landing/pages/[[language]]/comparison/helpcrunch.vue")
  },
  {
    name: "comparison-jivo",
    path: "/:language?/comparison/jivo",
    meta: jivoSMTYsxPiusMeta || {},
    component: () => import("/Users/ilya/Sites/spilky-landing/pages/[[language]]/comparison/jivo.vue")
  },
  {
    name: "index",
    path: "/:language?",
    meta: indexUkQhYVIbSwMeta || {},
    component: () => import("/Users/ilya/Sites/spilky-landing/pages/[[language]]/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/:language?/privacy-policy",
    meta: privacy_45policyI30wEseNi0Meta || {},
    component: () => import("/Users/ilya/Sites/spilky-landing/pages/[[language]]/privacy-policy.vue")
  },
  {
    name: "tariffs",
    path: "/:language?/tariffs",
    meta: tariffsUPlKWr9RcPMeta || {},
    component: () => import("/Users/ilya/Sites/spilky-landing/pages/[[language]]/tariffs.vue")
  },
  {
    name: "user-agreement",
    path: "/:language?/user-agreement",
    meta: user_45agreementFzqNtvkX2BMeta || {},
    component: () => import("/Users/ilya/Sites/spilky-landing/pages/[[language]]/user-agreement.vue")
  },
  {
    name: "errors-403",
    path: "/errors/403",
    meta: _403Aov5PKaN5rMeta || {},
    component: () => import("/Users/ilya/Sites/spilky-landing/pages/errors/403.vue")
  },
  {
    name: "errors-404",
    path: "/errors/404",
    meta: _404lHVSljmyHrMeta || {},
    component: () => import("/Users/ilya/Sites/spilky-landing/pages/errors/404.vue")
  },
  {
    name: "errors-500",
    path: "/errors/500",
    meta: _500VgkNbRSvIpMeta || {},
    component: () => import("/Users/ilya/Sites/spilky-landing/pages/errors/500.vue")
  }
]